import React from 'react';

const NotFound = () => {
    return (
        <h1>
           404, Nothing is here
        </h1>
    );
};

export default NotFound;